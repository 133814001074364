// import { axiosRequest } from "../../main"
import axiosRequest from "../../AuthenticatedRoute"
// import { buildFormData } from "../../utils"
import {getImprovisedTaskFormat} from "../../utils/utilsTasks"

const state={
    currentTime:'00:00:00',
    currentEndOfTime:null,
    currentInterval:null,
    isWorking:false,
    isPaused:false,
    stoppedDuration:null,
    startTime:null,
    timeStoped:null,
    projectName:null,
    taskName:null,
    minsSpentOnTask:null,
    currentTaskOfStopWatch:null,
    project: null,
    displayOverlayTimer: false
}

const mutations={
    setProject(state,project){
        state.project=project
    },
    setDisplayOverlayTimer(state,display){
        state.displayOverlayTimer=display
    },
    setProjectName(state,name){
        state.projectName=name
    },
    setTaskName(state,name){
        state.taskName=name
    },
    setCurrentTime(state,time){
        state.currentTime=time
    },
    setCurrentEndOfTime(state,time){
        state.currentEndOfTime=time
    },
    setCurrentInterval(state,interval){
        state.currentInterval=interval
    },
    setStateOfWorlking(state,isWorking){
        state.isWorking=isWorking
    },
    setStateOfPause(state,isPaused){
        state.isPaused=isPaused
    },
    setStoppedDuration(state,duration){
        state.stoppedDuration=duration
    },
    setStartTime(state,time){
        state.startTime=time
    },
    setTimeStoped(state,time){
        state.timeStoped=time
    },
    convertMinsSpentOnTask(state){
        var temp=state.currentTime
        var splitedTmp=temp.split(':')
        var minutes=(+splitedTmp[0])*60+(+splitedTmp[1])
        state.minsSpentOnTask=parseInt(minutes)
    },
    setCurrentTaskOfStopWatch(state,task){
        state.currentTaskOfStopWatch=task
    }
}

const actions={
    saveStopWatchStatement({rootState,commit,state,dispatch}){
        const data={
            minutesWorked:state.minsSpentOnTask,
            status:'Validé',
        }

        return new Promise((resolve,reject)=>{
            axiosRequest({
                method:'PUT',
                url:'/project/sub/improvised-task/stop-chrono/'+rootState.stopWatchModule.currentTaskOfStopWatch.id,
                headers:{
                    Authorization:'Bearer '+rootState.account.userInfos.token
                },
                data:JSON.stringify(data)
            }).then(reponse=>{
                const responseData=reponse.data.data
                commit('improvisedTask/setStoppedChronoToTask',responseData,{root:true})
                commit('setCurrentTaskOfStopWatch',null)
                commit('improvisedTask/setFilteredImprovisedTasks',{rootState},{root:true})
                var data=getImprovisedTaskFormat(responseData)
                data.stopwatchCase=true
                dispatch('validTimeModule/updateImprovisedTask',data,{root:true})
                resolve(reponse)
            }).catch(error=>{
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}

