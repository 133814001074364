/* eslint-disable no-unused-vars */
import axios from "axios";
import axiosRequest from "@/AuthenticatedRoute";
import {
    SET_ACTIVE_TEAM_BILLINGS,
    ADD_ARCHIVED_BILLING,
    ADD_BILLING,
    ADD_ISSUED_BILLING,
    ADD_TEAM_BILLING,
    DELETE_ISSUED_BILLING,
    DELETE_TEAM_BILLING,
    RESET_TEAM_BILLING,
    SET_CHOSEN_BILLING_OBJ,
    SET_CURRENT_PROJECT_INVOICING_SLUG,
    SET_WIDGET_INFO,
    TOGGLE_MODAL_REMINDER,
    UPDATE_TEAM_BILLING,
    UPDATE_TEAM_BILLINGS,
    UPDATE_PROVISION,
    UPDATE_ISSUED_BILLING,
    SET_PDF_PREVIEWER_TYPE
} from "@/store/mutations-types";
import { dateFormatted1, getObjectKey, isDefined, fixingNumberToDecimal, getFormatDate, capitalize, isDefinedNumElseZero } from "@/utils";
import { buildFormData, downloadURI } from "../../utils";

// const rootSlug = 'root';

const namespaced = true;



const state = {
    isLoading: false,
    current_project_title: '',
    hoursBillingArrObjs: [],
    provisionTeamArrObjs: [],
    provisionTeamObj: {},
    downloadPdfUrl: {},
    billings: [],
    billingsLoaded: false,
    current_project_invoicing_slug: '',
    teamBillings: [],
    activeBillings: [],
    archivedBillings: [],
    showModalReminder: false,
    widgetInfo: null,
    draftInvoiceObj: null,

    current_client_billing: {},

    chosenBillingObj: null,

    current_tva_index: 0,
    currentVATList: [],
    pdfPreviewType: 'normal'
}

const getters = {
    getViewOfTeamsInvoices(state) {
        var view = []
        for (let element of state.teamBillings) {
            var obj = {
                teamName: element.teamName,
                teamId: element.team.id,
                members: getMemberInvoicesView(element.memberInvoices),
            }
            view.push(obj)
        }
        return view
    },

    availableForBilling(state) {
        var result = false
        state.billings.forEach(element => {
            if (element.slug === state.current_project_invoicing_slug && element.timeValidation !== 'En attente') {
                result = true
            }
        });
        return result
    },

    getTeamsBilling(state) {
        if (state.teamBillings.length > 0) {
            return state.teamBillings
        }
        return []
    },

    getProvisionTeamObj(state) {
        return state.provisionTeamArrObjs
    }
}

const mutations = {
    setDraftInvoiceObj(state, obj) {
        state.draftInvoiceObj = obj
    },
    //Billing
    [ADD_BILLING](state, { billings }) {
        for (const billing of billings) {
            if (state.billings.filter(item => item.codeId === billing.codeId).length === 0) {
                state.billings.push(billing)
            }
        }
        //UPDATING
        for (const billing of billings) {
            state.billings.map(element => {
                if (element.codeId === billing.codeId) {
                    return billing
                }
                return element
            })
        }
    },
    setDownloadPdfUrl(state, url) {
        state.downloadPdfUrl = url
    },

    [SET_CHOSEN_BILLING_OBJ](state, obj) {
        state.chosenBillingObj = obj
    },

    setEmptyTeamBilling(state) {
        state.teamBillings = []
    },

    [SET_CURRENT_PROJECT_INVOICING_SLUG](state, obj) {

        state.current_project_invoicing_slug = obj.slug
        state.current_project_title = obj.project
        state.current_client_billing = obj.client
    },

    [SET_WIDGET_INFO](state, info) {
        state.widgetInfo = info
    },

    [SET_PDF_PREVIEWER_TYPE](state, type) {
        state.pdfPreviewType = type
    },
    
    [ADD_TEAM_BILLING](state, { teamBillings }) {
        for (const teamBilling of teamBillings) {

            if (state.teamBillings.filter(item => item.slug === teamBilling.slug).length === 0) {
                state.teamBillings.push(teamBilling)
            }
        }
    },

    [UPDATE_TEAM_BILLINGS](state, { teamBillings }) {
        for (const teamBilling of teamBillings) {
            state.teamBillings = state.teamBillings.map(element => {
                if (element.slug === teamBilling.slug) {
                    return teamBilling
                }
                return element
            })
        }
    },

    [UPDATE_TEAM_BILLING](state, teamBilling) {
        state.teamBillings = state.teamBillings.map(element => {
            if (element.slug === teamBilling.slug) {
                return teamBilling
            }
            return element
        })
    },

    [DELETE_TEAM_BILLING](state, codeId) {
        state.teamBilling.filter(element => { return element.codeId !== codeId })
    },
    setCurrentTvaIndex(state) {
        if(state.teamBillings.length>0){
            state.current_tva_index = state.teamBillings[0].tva
        }

    },
    [RESET_TEAM_BILLING](state) {
        state.teamBillings = []
    },

    [SET_ACTIVE_TEAM_BILLINGS](state, {activeBillings}) {
        state.activeBillings = activeBillings
    },

    [ADD_ISSUED_BILLING](state, { activeBillings }) {
        for (const issuedBilling of activeBillings) {
            if (state.activeBillings.filter(item => item.codeId === issuedBilling.codeId).length === 0) {
                state.activeBillings.push(issuedBilling)
            }
        }
    }
    ,

    [UPDATE_ISSUED_BILLING](state, billing) {
        state.activeBillings = state.activeBillings.map(element => {
            if (element.slug === billing.codeId) {
                return billing
            }
            return element
        })
    },

    [ADD_ARCHIVED_BILLING](state, { archivedBillings }) {

        for (const archivedBilling of archivedBillings) {
            if (state.archivedBillings.filter(item => item.codeId === archivedBilling.codeId).length === 0) {
                state.archivedBillings.push(archivedBilling)
            }
        }
    },

    [TOGGLE_MODAL_REMINDER](state) {
        state.showModalReminder = !state.showModalReminder
    },

    [DELETE_ISSUED_BILLING](state, { codeId }) {
        state.activeBillings = state.activeBillings.filter(element => { return element.codeId !== codeId })
    },

    [UPDATE_PROVISION](state, provision) {
        state.widgetInfo.provision = provision
    },

    setHoursBillingArrObjs(state, obj) {
        state.hoursBillingArrObjs = obj
    },

    setProvisionTeamArrObjs(state, obj) {
        state.provisionTeamArrObjs = obj
    },


    // setProvisionTeamArrObj(state,obj){
    //     state.provisionTeamArrObjs=obj
    // },

    setProvisionTeamObj(state, obj) {
        state.provisionTeamObj = obj
    },

    setOnNullBillings(state) {
        state.billings = []
    },

    updateSettlement(state, settlement_codeId) {
        state.activeBillings = state.activeBillings.map(element => {
            if (element.codeId === settlement_codeId.codeId) {
                element.partialSettlement = settlement_codeId.partialSettlement
                element.restSettlement = element.amountTaxed - settlement_codeId.partialSettlement
                return element
            }
            return element
        })
    },
    setVATList(state, list) {
        state.currentVATList = list.map(element => {
            return {
                value: element.name,
                text: element.name + '%'
            }
        })
    }
}

const actions = {
    loadVATList({ commit }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/tva/',
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)){
                    commit('setVATList', data)
                }
                // if (isDefined(data)) {
                //     const billings = buildBillings(data)

                //     commit(ADD_BILLING, { billings })
                // }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updateTeamBillingsInstantly({commit},arr){
        const teamBillings=buildTeamBillings(arr)
        commit(UPDATE_TEAM_BILLINGS,{ teamBillings })
        commit('setCurrentTvaIndex')
    },
    setBillingObj({ commit }, obj) {
        commit(SET_CHOSEN_BILLING_OBJ, obj)
    },

    setOnNullBillings({ commit }) {
        commit('setOnNullBillings')
    },

    setPdfPreviewerType({ commit }, type) {
        commit(SET_PDF_PREVIEWER_TYPE, type)
    },

    deductPostponedAmount({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/team-invoice/deduct-postponed-amount/' + data.codeId,
                data: data
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    const teamBilling = buildTeamBilling(data)
                    commit(UPDATE_TEAM_BILLING, teamBilling)
                    // dispatch('updateHoursBillObj',data)
                    resolve(response)
                }
            }).catch(error => reject(error))
        })
    },
    loadBillings({ commit, state }) {
        state.isLoading = true
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/invoice/list',
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    const billings = buildBillings(data)

                    commit(ADD_BILLING, { billings })
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            }).finally(() => {
                state.isLoading = false
            })
        })
    },

    loadProjectBilling({ _ }, projectSlug) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/invoice/' + projectSlug,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    const invoice = buildBilling(data)
                    resolve(invoice)
                }
                
            }).catch(error => {
                reject(error)
            })
        })
    },

    setCurrentProjectInvoiceObj({ commit }, obj) {
        commit(SET_CURRENT_PROJECT_INVOICING_SLUG, obj)
    },

    setNullTeamBillingList({ commit }) {
        commit('setEmptyTeamBilling')
    },

    async loadTeamBillings({ commit, state }, createInvoiceCase = false) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/team-invoice/list/' + state.current_project_invoicing_slug,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    const teamBillings = buildTeamBillings(data)

                    commit('setHoursBillingArrObjs', buildHoursBillArrObjs(data))
                    // commit('setProvisionTeamArrObjs', buildProvisionTeamArrObjs(data))
                    // commit('setProvisionTeamObj', buildProvisionTeamObj(data))

                    if (createInvoiceCase) {
                        commit(UPDATE_TEAM_BILLINGS, { teamBillings })
                    } else {
                        commit(RESET_TEAM_BILLING)
                        commit(ADD_TEAM_BILLING, { teamBillings })
                    }
                    commit('setCurrentTvaIndex')
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadActiveBillings({ commit, state }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/sent-invoice/active/' + state.current_project_invoicing_slug,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    const activeBillings = buildActiveBillings(data)

                    commit(SET_ACTIVE_TEAM_BILLINGS, { activeBillings })
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadArchivedBillings({ commit, state }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/sub/sent-invoice/archived/' + state.current_project_invoicing_slug,
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    const archivedBillings = buildArchivedBillings(data)

                    commit(ADD_ARCHIVED_BILLING, { archivedBillings })

                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async calculateInvoice({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                methods: 'POST',
                url: 'project/sub/sent-invoice/calculate',
                data: data,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response.data);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                commit('setDownloadPdfUrl', url);
                // // Create a link element to trigger the download
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'bill.pdf');
                // document.body.appendChild(link);
                // link.click();
                // // Clean up resources
                // window.URL.revokeObjectURL(url);
                // document.body.removeChild(link);

                resolve(response)
            }).catch(err => reject(err))
        })
    },

    async sendInvoice({ commit }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: 'project/sub/sent-invoice/',
                data: data,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'facture.pdf';
                a.click();
               
                resolve(response)
                // const data = response.data.data
                // if (isDefined(data)) {
                //     const activeBillings = buildActiveBillings([data])

                //     commit(ADD_ISSUED_BILLING, { activeBillings: activeBillings })

                //     resolve(response)
                // }
            }).catch(error => {
                reject(error)
            }
            )
        })
    },

    resetTeamBillings({ commit }) {
        commit(RESET_TEAM_BILLING)
    },

    async updateTeamInvoice({ commit }, {data,noUpdateCase=false}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/team-invoice/' + data.codeId,
                data: data
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    const teamBilling = buildTeamBilling(data)
                    //I need the array of responses to get the table of team_billings instatly after using Promise.all in teamInvoice.vue
                    if(!noUpdateCase){
                        commit(UPDATE_TEAM_BILLING, teamBilling)
                        commit('setCurrentTvaIndex') 
                    }
                    // dispatch('updateHoursBillObj',data)
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getInfoWidget({ commit, state }) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/project/global-informations/' + state.current_project_invoicing_slug
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    const info = buildWidgetInfo(data)
                    commit(SET_WIDGET_INFO, info)
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateMemberInvoice(_, obj) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/member-invoice/' + obj.codeId,
                data: obj
            }).then(response => {
                if (response.status === -1) {
                    reject()
                } else {
                    resolve(response)
                }

            }).catch(error => {
                reject(error)
            })
        })
    },

    async addProvision({_}, data){
        const body = buildFormData({
            provision: data.provision,
            scheduled_billing_date: data.scheduled_billing_date
        })
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/project/sub/sent-invoice/create-provision/' + data.projcetSlug,
                data: body,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            }).then(response => {
                if(response.status === 200){
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'facture.pdf';
                    a.click();
                    
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },


    //NEW
    async updateProvision({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/update-provision/' + state.current_project_invoicing_slug,
                data: data
            }).then(response => {
                const data = response.data.data.provision
                if (isDefined(data)) {
                    commit(UPDATE_PROVISION, data)
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateSettlement({ commit }, settlement_codeId) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/sent-invoice/update-reglement/' + settlement_codeId.codeId,
                data: { reglementPartiel: parseInt(settlement_codeId.partialSettlement) }
            }).then(response => {

                if (response.status >= 200 && response.status < 300) {
                    commit('updateSettlement', settlement_codeId)
                    resolve(response)
                } else {
                    reject()
                }
            }).catch(err => reject(err))
        })
    },

    async createAvoir({ commit, state }, data) {
        if(!data.referenceInvoice) return;
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/project/sub/sent-invoice/create-avoir/' + state.current_project_invoicing_slug,
                data: {
                    amount: data.amount,
                    referenceInvoice: data.referenceInvoice,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob',
            }).then(response => {
                // const data = response.data.data
                // if (isDefined(data)) {
                //     const activeBillings = buildActiveBillings([data])

                //     commit(ADD_ISSUED_BILLING, { activeBillings })
                // }
                // resolve(response)
                if(response.status === 200){
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'facture.pdf';
                    a.click();
                    
                    resolve(response)
                }
            }).catch(error => { reject(error) })
        })
    },

    async cancelInvoice({ commit }, invoice) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/sent-invoice/cancel/' + invoice,
            }).then(response => {

                const data = response.data.data
                if (isDefined(data)) {
                    const canceledBilling = buildArchivedBillings([data])
                    commit(ADD_ARCHIVED_BILLING, { archivedBillings: [canceledBilling[0]] })
                    commit(DELETE_ISSUED_BILLING, { codeId: data.codeId })
                }
                resolve(response)
            }).catch(error => { reject(error) })
        })
    },

    async addInvoiceSlug({_}, data) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/sent-invoice/addInvoiceFile/' + data.invoiceId,
                data: {invoiceSlug: data.invoiceSlug}
            }).then(response => {
                resolve(response)
            }).catch(error => { reject(error) })
        })
    },

    async generateBillingFileUrl({ commit }, { projectSlug, title, reference }) {
        const data = buildFormData({ title: title, reference: reference })
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'POST',
                url: '/project/sub/ged/file/' + projectSlug + '/billingFileUrl',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(response => {

                if (isDefined(response.data) && response.data.status) {
                    const downloadUrl = response.data.data.url
                    commit('setDownloadPdfUrl', downloadUrl)
                    resolve(response)
                }
            }).catch(error => { reject(error) })
        })
    },

    // convertWordToPdf({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             method: 'POST',
    //             url: process.env.VUE_APP_ONLYOFFICE_URL + '/ConvertService.ashx',
    //             data: data,
    //         }).then(response => {
    //             // const parser = new DOMParser();
    //             // const xmlDoc = parser.parseFromString(response.data, 'text/xml');

    //             // // Step 2: Traverse the DOM to access the data
    //             // // Here you can access and use the parsed XML data as needed
    //             // // For example, you can extract specific elements and attributes:
    //             // const isEnded = xmlDoc.querySelector('EndConvert').textContent
    //             const data=response.data
    //             if(isDefined(data)){
    //                 const downloadUrl = data.fileUrl
    //                 commit('setDownloadPdfUrl',downloadUrl)
    //             }
    //             resolve(response)
    //         }).catch(error=>{
    //             reject(error)
    //         })
    //     })
    // },
    // eslint-disable-next-line no-unused-vars
    getFileFromDownloadUrl({ _ }, url) {
        return new Promise((resolve, reject) => {

            // const link = document.createElement('a');
            // link.href = url;
            // //or any other extension
            // document.body.appendChild(link);
            // link.click();
            // resolve()
            axios({
                methods: 'GET',
                url: url,
                responseType: 'blob'
            }).then(response => {
                const blob = response.data
                const filename = extractFilenameFromUrl(url);

                downloadURI(blob, filename)
                const file = new File([blob], filename, { type: blob.type })
                resolve(file)
            }).catch(error => {

                reject(error)
            })
        })
    },

    async archiveInvoice({ commit, state }, codeId) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/project/sub/sent-invoice/archive/' + codeId,
            }).then(response => {

                const data = response.data.data
                const billingToArchive = state.activeBillings.filter(element => { return element.codeId === codeId })

                if (isDefined(billingToArchive)) {
                    const newBillingToArchive = buildArchivedBillings([data])
                    commit(ADD_ARCHIVED_BILLING, { archivedBillings: newBillingToArchive })
                    commit(DELETE_ISSUED_BILLING, { codeId: newBillingToArchive[0].codeId })
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateProvisionTeamObj({ commit }, obj) {
        commit('setProvisionTeamObj', obj)
        commit('setProvisionTeamArrObjs', obj)
    },

    toggleModalReminder({ commit }) {
        commit(TOGGLE_MODAL_REMINDER)
    },

    updateHoursBillObj({ state }, obj) {
        var team = obj.team
        var memberInvoices = obj.memberInvoices
        state.hoursBillingArrObjs.forEach((element, i) => {
            var arr = element[team.id]
            arr.forEach((el, j) => {
                memberInvoices.forEach((member) => {
                    state.hoursBillingArrObjs[i][team.id][j][member.codeId].billableHours = member.billableHours
                })

            })
        })
    },

    setBillingsOnNull({ state }) {
        state.hoursBillingArrObjs = []
        state.provisionTeamArrObjs = []

        state.current_project_invoicing_slug = ''
        state.teamBillings = []
        state.activeBillings = []
        state.archivedBillings = []
        state.widgetInfo = null
    }

}

export default {
    namespaced,
    getters,
    state,
    actions,
    mutations,
}

function buildWidgetInfo(item) {
    return {
        "amountBilled": getObjectKey(item, 'amountBilled'),
        "realizationRate": getObjectKey(item, 'realizationRate') < 100 ? getObjectKey(item, 'realizationRate') : 100,
        "estimatedTotalBudget": getObjectKey(item, 'estimatedTotalBudget'),
        "customerCreditDays": getObjectKey(item, 'customerCreditDays'),
        "totalFees": getObjectKey(item, 'totalFees'),
        "provision": isDefinedNumElseZero(getObjectKey(item, 'provision'))//?getObjectKey(item,'provision'):0
    }
}
function extractFilenameFromUrl(url) {
    const parts = url.split('=');
    return parts[parts.length - 1];
}

function buildBillings(data) {
    let result = []

    for (let item of data) {
        result.push(buildBilling(item))
    }

    return result
}

function buildBilling(item, returnArray = false) {
    let result = null

    if (isDefined(item)) {
        result = {
            "codeId": getObjectKey(item, 'codeId'),
            "slug": getObjectKey(getObjectKey(item, 'project'), 'codeID'),
            "project": getObjectKey(getObjectKey(item, 'project'), 'name'),
            "timeValidation": getObjectKey(item, 'timeValidation'),
            "billableAmount": fixingNumberToDecimal(getObjectKey(item, 'billableAmount')),
            "realizationRate": getObjectKey(item, 'realizationRate'),
            "toBeBilled": fixingNumberToDecimal(getObjectKey(item, 'toBeBilled')),
            "sendingDate": dateFormatted1(getObjectKey(item, 'sendingDate')),
            "client": getObjectKey(item, 'client')
        }

        if (returnArray) {
            result = [result]
        }
    }

    return result
}

function buildTeamBillings(data) {
    let result = []

    for (let item of data) {
        result.push(buildTeamBilling(item))

    }

    return result
}

function buildTeamBilling(item, returnArray = false) {
    let result = null

    if (isDefined(item)) {
        result = {
            "slug": getObjectKey(item, 'codeId'),
            "teamName": getObjectKey(getObjectKey(item, 'team'), 'name'),
            "billableAmount": fixingNumberToDecimal(getObjectKey(item, 'billableAmount')),
            "billableAmountAdjusted": fixingNumberToDecimal(getObjectKey(item, 'billableAmountAdjusted')),//adjustedWip=billableAmountAdjusted
            "amountPostponed": fixingNumberToDecimal(getObjectKey(item, 'amountPostponed')), //postponed=amountPostponed
            "fees": fixingNumberToDecimal(getObjectKey(item, 'totalFees')),
            "tva": getObjectKey(item, 'tva'),
            "provision": getObjectKey(item, 'provision'),
            "toInvoiceTaxed": fixingNumberToDecimal(getObjectKey(item, 'toInvoiceTaxed')),
            "tasks": getObjectKey(item, 'tasks'),
            "toInvoice": fixingNumberToDecimal(getObjectKey(item, 'toInvoice')),//billingCharge - toInvoice
        }

        result['team'] = item['team']


        result['sentInvoice'] = item['sentInvoice']



        result['memberInvoices'] = item['memberInvoices']


        if (returnArray) {
            result = [result]
        }
    }

    return result
}

function buildActiveBillings(data) {
    let result = []

    for (let item of data) {
        result.push(buildIssuedBilling(item))
    }

    return result
}

function buildIssuedBilling(item, returnArray = false) {
    let result = null
    if (isDefined(item)) {
        result = {
            "codeId": getObjectKey(item, 'codeId'),
            "billNumber": getObjectKey(item, 'billNumber'),
            "amount": fixingNumberToDecimal(getObjectKey(item, 'amount')),
            "amountTaxed": fixingNumberToDecimal(getObjectKey(item, 'amountTaxed')),
            "partialSettlement": isDefinedNumElseZero(getObjectKey(item, 'reglementPartiel')),
            "restSettlement": fixingNumberToDecimal(isDefinedNumElseZero(getObjectKey(item, 'rest'))),
            "avoir": isDefinedNumElseZero(getObjectKey(item, 'avoir')),
            "customerCreditDays": fixingNumberToDecimal(getObjectKey(item, 'customerCreditDays')),
            "sendingDate": dateFormatted1(getObjectKey(item, 'sendingDate')),
            "canceled": getObjectKey(item, 'canceled'),
            "invoiceSlug": getObjectKey(item, 'invoiceSlug')
        }
        if (returnArray) {
            result = [result]
        }
    }

    return result
}

function buildArchivedBillings(data) {
    let result = []

    for (let item of data) {
        result.push(buildArchivedBilling(item))
    }

    return result
}

function buildArchivedBilling(item, returnArray = false) {
    let result = null

    if (isDefined(item)) {
        result = {
            "codeId": getObjectKey(item, 'codeId'),
            "billNumber": getObjectKey(item, 'billNumber'),
            "amount": getObjectKey(item, 'amount'),
            "paymentDate": dateFormatted1(getObjectKey(item, 'paymentDate')),
            "sendingDate": dateFormatted1(getObjectKey(item, 'sendingDate')),
            "customerCreditDays": getObjectKey(item, 'customerCreditDays'),
            "partialSettlement": isDefinedNumElseZero(getObjectKey(item, 'reglementPartiel')),
            "restSettlement": fixingNumberToDecimal(isDefinedNumElseZero(getObjectKey(item, 'rest'))),
            "avoir": isDefinedNumElseZero(getObjectKey(item, 'avoir')),
            "canceled": getObjectKey(item, 'canceled')
        }
        if (result.canceled === true) {
            result.codeId = result.codeId + '  Annulé'
        }
        if (returnArray) {
            result = [result]
        }
    }

    return result

}

function getMemberInvoicesView(memberInvoices) {
    var objects = []
    for (let obj of memberInvoices) {
        var element = {
            memberName: capitalize(obj.projectMember.user.firstname) + ' ' + obj.projectMember.user.lastname.toUpperCase(),
            memberEmail: obj.projectMember.user.email,
            hourlyWage: obj.projectMember.hourlyWage,
            fees: obj.fees,
            role: obj.projectMember.role,
            producedHours: obj.producedHours,
            billableHours: obj.billableHours,
            memberInvoiceCodeId: obj.codeId
        }
        objects.push(element)
    }
    return objects
}

function buildProvisionTeamArrObjs(obj) {
    var returnData = []
    obj.forEach(element => {
        var teamId = element.team.id
        var tmpObj = {}
        tmpObj[teamId] = element.provision
        returnData.push(tmpObj)
    })
    return returnData
}

function buildProvisionTeamObj(obj) {
    var tmpObj = {}
    obj.forEach(element => {
        var teamId = element.team.id
        tmpObj[teamId] = element.provision
        //returnData.push(tmpObj)
    })
    return tmpObj
}

function buildHoursBillArrObjs(obj) {
    var returnData = []
    obj.forEach(element => {
        var teamId = element.team.id
        var tmpObj = {}
        tmpObj[teamId] = buildHoursBillArrObj(element)
        returnData.push(tmpObj)
    })
    return returnData
}

function buildHoursBillArrObj(obj) {
    var returnData = []
    var memberInvoices = obj.memberInvoices
    memberInvoices.forEach(inv => {
        var tmpObject = {}
        tmpObject[inv.codeId] = { 
            'user': inv.projectMember.user.email, 
            'producedHours': fixingNumberToDecimal(inv.producedHours), 
            'postPonedHours': fixingNumberToDecimal(inv.postPonedHours),
            'toPostPonedHours': fixingNumberToDecimal(inv.toPostPonedHours),
            'modulatedHours': fixingNumberToDecimal(inv.modulatedHours),
            'billableHours': fixingNumberToDecimal(inv.billableHours)
        }
        returnData.push(tmpObject)
    })
    return returnData
}